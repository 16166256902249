'use client';

import { datadogRum } from '@datadog/browser-rum';
import { env } from 'src/env.mjs';

datadogRum.init({
    applicationId: env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'moshy-headless-site',
    env: env.NEXT_PUBLIC_VARIANT,
    sessionSampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

export default function DatadogInit() {
    return null;
}
