import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
    skipValidation:
        process.env.NODE_ENV === 'test' || process.env.STORYBOOK === 'true',
    server: {
        PRISMIC_ACCESS_TOKEN: z.string().min(1),
        INDEX_NOW_BASE_PATH: z.string().url().min(1),
        INDEX_NOW_KEY: z.string().min(1),
        QUIZ_BASE_PATH: z.string().url().min(1),
        GROWTHBOOK_CLIENT_KEY: z.string().min(1),
    },
    client: {
        NEXT_PUBLIC_BASE_PATH: z.string().url().min(1),
        NEXT_PUBLIC_CHARGEBEE_SITE: z.string().min(1),
        NEXT_PUBLIC_CHARGEBEE_KEY: z.string().min(1),
        NEXT_PUBLIC_DATADOG_APPLICATION_ID: z.string().min(1),
        NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: z.string().min(1),
        NEXT_PUBLIC_GOOGLE_MAPS_KEY: z.string().min(1),
        NEXT_PUBLIC_MOSH_BASE_PATH: z.string().url().min(1),
        NEXT_PUBLIC_RESTFUL_KEY: z.string().min(1),
        NEXT_PUBLIC_RESTFUL_USER: z.string().min(1),
        NEXT_PUBLIC_SEGMENT_WRITE_KEY: z.string().min(1),
        NEXT_PUBLIC_SEGMENT_CDN_URL: z.string().min(1),
        NEXT_PUBLIC_SEGMENT_API_HOST: z.string().min(1),
        NEXT_PUBLIC_USER_PORTAL_PATH: z.string().url().min(1),
        NEXT_PUBLIC_VARIANT: z.enum(['development', 'staging', 'production']),
    },
    runtimeEnv: {
        NEXT_PUBLIC_BASE_PATH: process.env.NEXT_PUBLIC_BASE_PATH,
        NEXT_PUBLIC_CHARGEBEE_SITE: process.env.NEXT_PUBLIC_CHARGEBEE_SITE,
        NEXT_PUBLIC_CHARGEBEE_KEY: process.env.NEXT_PUBLIC_CHARGEBEE_KEY,
        NEXT_PUBLIC_DATADOG_APPLICATION_ID:
            process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
        NEXT_PUBLIC_DATADOG_CLIENT_TOKEN:
            process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        NEXT_PUBLIC_GOOGLE_MAPS_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
        NEXT_PUBLIC_MOSH_BASE_PATH: process.env.NEXT_PUBLIC_MOSH_BASE_PATH,
        NEXT_PUBLIC_RESTFUL_KEY: process.env.NEXT_PUBLIC_RESTFUL_KEY,
        NEXT_PUBLIC_RESTFUL_USER: process.env.NEXT_PUBLIC_RESTFUL_USER,
        NEXT_PUBLIC_SEGMENT_WRITE_KEY:
            process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
        NEXT_PUBLIC_SEGMENT_CDN_URL: process.env.NEXT_PUBLIC_SEGMENT_CDN_URL,
        NEXT_PUBLIC_SEGMENT_API_HOST: process.env.NEXT_PUBLIC_SEGMENT_API_HOST,
        NEXT_PUBLIC_USER_PORTAL_PATH: process.env.NEXT_PUBLIC_USER_PORTAL_PATH,
        NEXT_PUBLIC_VARIANT: process.env.NEXT_PUBLIC_VARIANT,
        PRISMIC_ACCESS_TOKEN: process.env.PRISMIC_ACCESS_TOKEN,
        INDEX_NOW_BASE_PATH: process.env.INDEX_NOW_BASE_PATH,
        INDEX_NOW_KEY: process.env.INDEX_NOW_KEY,
        QUIZ_BASE_PATH: process.env.QUIZ_BASE_PATH,
        GROWTHBOOK_CLIENT_KEY: process.env.GROWTHBOOK_CLIENT_KEY,
    },
});
